import React from "react";

import headerCar from "../../images/troubleticket-header.png";
import shapeBoxes from "../../images/shape_boxes.png";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import twitter from "../../images/twitter.svg";
import linkedin from "../../images/linkedin.svg";
import Navbar from "../layout/navbar";

const Header = ({ siteTitle }) => (
  <section className="hero home-header">
    <Navbar />
    <div className="auto-header">
      <div className="intro">
        <h1 className="has-text-white">
          Trouble <br />
          Ticket
          <br />
        </h1>
        <p>We are ready to work and resolve your car issues.</p>
        <img src={shapeBoxes} alt="shapes" className="shapes" />
      </div>
      <div className="image-placeholder">
        <img src={headerCar} alt="banner" className="banner" />
        <div className="socials">
          <a href="https://www.facebook.com/mycarviva" target="_blank">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/mycarviva/" target="_blank">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://www.twitter.com/mycarviva" target="_blank">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/mycarviva" target="_blank">
            <img src={linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Header;
