import React from "react";
import Modal from "react-modal";
import { Link } from "gatsby";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import TicketHeader from "../components/headers/ticket";
import TicketMidSection from "../components/contents/ticket";
import Footer from "../components/layout/footer";
import SparePartModal from "../images/modal_cartroubles.jpg";

const TicketPage = () => {
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setShowModal(true), 500);
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="modal_content"
        overlayClassName="modal_overlay"
        contentLabel="Example Modal"
      >
        <button class="close" onClick={() => setShowModal(false)}>
          &#x2715;
        </button>
        <button onClick={() => setShowModal(false)}>
          <img src={SparePartModal} alt="modal" />
        </button>
      </Modal>
      <div className="homepage quotes">
        <Helmet />
        <TicketHeader />
        <TicketMidSection />
        <Footer />
      </div>
    </>
  );
};

export default TicketPage;
